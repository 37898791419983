import React from 'react'
// import Navbar from '../components/Navbar/Navbar'
// import JoinForm from '../components/Join/join'
import Footer from '../components/Footer/Footer'
import FixedButton from '../components/FixedButton/FixedButton'
import ScrollToTop from '../components/ScrollToTop/top'
import Test from '../components/test/test'
import Navbar from '../components/NavBar/Navbar'
export default function JoinUs() {
  return (
    <div>
    <Navbar />
    <Test />
    <Footer />
    <FixedButton />
    <ScrollToTop />
    
    </div>
  )
}
