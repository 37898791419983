import React from 'react'
import './ballers.css'
export default function ballers() {
  return (
    <div className='main-ballers'>
      <h1 className='animation-ballers'> BALLERS </h1>
    <div className="image-container">
    </div>
    </div>
  )
}
