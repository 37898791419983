import React from 'react'
import './test.css'
import './dialog.css'
import { useTranslation } from 'react-i18next';
import { useState , useRef } from 'react';
import axios from 'axios';
import success from '../images/success.png';
export default function Test() {
    const { t } = useTranslation();
    const [data , setData] = useState({});
    const[response , setResponse] = useState("");
    const [Cnum , setCnum] = useState(0);
    const cNum = useRef();
    const modal = useRef();
    const modalRefuse = useRef();
    const inputHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData((values) => ({ ...values, [name]: value }));
    };
    const Children = ()=>{
        setCnum(cNum.current.value);
    }
    const generateInputs = () => {
        const inputs = [];
        for (let i = 0; i < Cnum; i++) {
          inputs.push(
            <input
              key={i}
              type="text"
              placeholder={`Child ${i + 1} Name`}
              name={`child${i+1}`}
              onChange={inputHandler}
              className="form-control"
            />
          );
        }
        return inputs;
      };
    const subHandler = (e)=>{
        e.preventDefault();
        axios.post('http://admin.ballersaqaba.com/api/join', data)
        .then(r=>{
            setResponse(r.data.message);
            if (r.data.data === 'Joined successfully') {
                modal.current.showModal();
                setTimeout(() => {
                    modal.current.close();
                }, 5000);
            }else{
                modalRefuse.current.showModal();
                setTimeout(() => {
                    modalRefuse.current.close();
                }, 5000);
            }
        })
    }
    return (
        <div>
            <div>
                <section className="contact section-padding">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-6 mx-auto col-md-7 col-12 py-5 mt-5 text-center"
                                data-aos="fade-up"
                            >
                                <h1 className="mb-4">
                                {t('content1-join')}  <strong>{t('content2-join')} </strong>
                                </h1>
                                <p>
                                {t('email-join1')}
                                    <a href="mailto:ballers@ballersaqaba.com">{t('email-join2')}</a>
                                </p>
                            </div>
                            <div className="col-lg-8 mx-auto col-md-10 col-12">
                                <form
                                    className="contact-form"
                                    data-aos="fade-up"
                                    data-aos-delay={300}
                                >
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="fullName"
                                                placeholder={t('fullNamePlaceholder')}
                                                onChange = {inputHandler}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder={t('emailPlaceholder')}
                                                onChange = {inputHandler}
                                                

                                            />
                                              
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                placeholder={t('addressPlaceholder')}
                                                onChange = {inputHandler}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="number"
                                        placeholder={t('phonePlaceholder')}
                                        onChange = {inputHandler}
                                      />
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <select className="form-control custom-dropdown" name="sessionType" id="" onChange = {inputHandler}>
                                                <option selected>{t('session1-join')}</option>
                                                <option value="regular">{t('session2-join')}</option>
                                                <option value="private">{t('session3-join')}</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <select className="form-control custom-dropdown" name="childre_number" id="" onChange={Children} ref={cNum}>
                                                <option selected>{t('session1-join')}</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                        </div>
                                        {generateInputs()}
                                        <div className="col-lg-5 mx-auto col-7">
                                            <input
                                                type="submit"
                                                className="form-control"
                                                id="submit-button"
                                                name="submit"
                                                onClick={subHandler}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <dialog close ref={modal} className='centered-dialog shadow'>
                    <div className="card p-3 " style={{width: '25rem'}}>
                        <div className='d-flex justify-content-center align-items-center'><div className='image'></div></div>
                        <div class="card-body">
                            <h6 className="card-title">Thank you for being one of ballers family</h6>
                            <p className="card-text">our team will contact you soon</p>
                        </div>
                        </div>
                    </dialog>
                    <dialog close ref={modalRefuse} className='centered-dialog shadow'>
                        <div className="card p-3 " style={{width: '25rem'}}>
                            <div className='d-flex justify-content-center align-items-center'><div className='image-reject'></div></div>
                            <div class="card-body">
                                <h6 className="card-title">{response}</h6>
                                <p className="card-text">please try again with valid data</p>
                            </div>
                        </div>
                    </dialog>
                </section>
            </div>
        </div>
    )
}
 