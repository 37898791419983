import React,{useEffect} from 'react'
import Wvideo from '../video/amro.mp4'
import './wavy.css'

export default function wavy() {
  
  return (


    <section className='main-wavy'>
    <video className="wavy" autoPlay muted loop>
        <source src={Wvideo} type="video/mp4" />
        Your browser does not support the video tag.
    </video>


    <svg className='waves' xmlnsXlink="http://www.w3.org/1999/xlink" id="wave" style={{ transform: 'rotate(0deg)', transition: '0.3s' }} viewBox="0 0 1440 400" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stopColor="rgba(241, 248, 253, 1)" offset="0%" />
          <stop stopColor="rgba(127.351, 168.826, 198.45, 1)" offset="100%" />
        </linearGradient>
      </defs>
      <path style={{ transform: 'translate(0, 0px)', opacity: 1 }} fill="url(#sw-gradient-0)" d="M0,120L30,153.3C60,187,120,253,180,246.7C240,240,300,160,360,160C420,160,480,240,540,280C600,320,660,320,720,286.7C780,253,840,187,900,153.3C960,120,1020,120,1080,140C1140,160,1200,200,1260,206.7C1320,213,1380,187,1440,193.3C1500,200,1560,240,1620,233.3C1680,227,1740,173,1800,160C1860,147,1920,173,1980,213.3C2040,253,2100,307,2160,273.3C2220,240,2280,120,2340,80C2400,40,2460,80,2520,100C2580,120,2640,120,2700,113.3C2760,107,2820,93,2880,80C2940,67,3000,53,3060,86.7C3120,120,3180,200,3240,246.7C3300,293,3360,307,3420,260C3480,213,3540,107,3600,60C3660,13,3720,27,3780,73.3C3840,120,3900,200,3960,200C4020,200,4080,120,4140,93.3C4200,67,4260,93,4290,106.7L4320,120L4320,400L4290,400C4260,400,4200,400,4140,400C4080,400,4020,400,3960,400C3900,400,3840,400,3780,400C3720,400,3660,400,3600,400C3540,400,3480,400,3420,400C3360,400,3300,400,3240,400C3180,400,3120,400,3060,400C3000,400,2940,400,2880,400C2820,400,2760,400,2700,400C2640,400,2580,400,2520,400C2460,400,2400,400,2340,400C2280,400,2220,400,2160,400C2100,400,2040,400,1980,400C1920,400,1860,400,1800,400C1740,400,1680,400,1620,400C1560,400,1500,400,1440,400C1380,400,1320,400,1260,400C1200,400,1140,400,1080,400C1020,400,960,400,900,400C840,400,780,400,720,400C660,400,600,400,540,400C480,400,420,400,360,400C300,400,240,400,180,400C120,400,60,400,30,400L0,400Z" />
    </svg>

</section>




  )
}
