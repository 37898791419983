import React from 'react'
import Coach from './coach'
import Technical from './technical'
import Nutrotion from './nutrotion'
export default function team() {
  return (
    <div>
      <Coach />
      <Technical />
      <Nutrotion />
    </div>
  )
}
